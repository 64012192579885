<template>
    <div>
        <div v-if="isLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
            <h6 class="text-body mt-3">Loading</h6>

        </div>
        <div v-if="!isLoading && errorLoading" style="min-height: 300px"
             class="d-flex align-items-center justify-content-center flex-column">
            <b-icon-exclamation-triangle class="h3" variant="danger"></b-icon-exclamation-triangle>
            <h6 class="text-danger mt-3">Ooops, there has been an error</h6>

        </div>
        <div v-if="!isLoading && !errorLoading">
            <h5 class="border-bottom pb-2 text-primary">{{ formData.id !== undefined ? "Edit" : "Create"}} nationality</h5>
            <form autocomplete="off">
                <b-form-group
                        label-size="sm"
                        label="Name:">
                    <b-form-input size="sm" v-model="formData.name" placeholder="Nationality name"/>
                    <error-display v-model="errors" ident="name"></error-display>
                </b-form-group>

                <b-form-group
                        label-size="sm"
                        label="Nationality code e.g. GBR:">
                    <b-form-input size="sm" v-model="formData.nationality_code" placeholder="3 char nationality code"/>
                    <error-display v-model="errors" ident="name"></error-display>
                </b-form-group>

                <b-form-group
                        label-size="sm"
                        label="Nationality group:">
                    <api-select-input
                            :original-options="nGroups"
                            v-model="formData.nationality_group_id"
                            :multiple="false"
                            api-endpoint-param="keywords"
                            :api-endpoint="apiEndpoint"
                            placeholder="Search for nationality group">

                    </api-select-input>
                    <error-display v-model="errors" ident="nationality_group_id"></error-display>
                </b-form-group>

                <b-form-group
                        label-size="sm"
                        label="Sort:">
                    <b-form-input size="sm" v-model="formData.sort" placeholder="Order in the list"/>
                    <error-display v-model="errors" ident="sort"></error-display>
                </b-form-group>

                <div class="text-right">
                    <b-button @click="saveModel" variant="success">Save</b-button>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
    import {getResource, saveResource} from "../../modules/api/methods";
    import ErrorDisplay from "../form_inputs/ErrorDisplay";
    import _ from 'lodash'
    import {
        baseApi,
        nationalityDetail, nationalityGroups,
        nationalitySave,
    } from "../../modules/api/endpoints";
    import {containsErrors} from "../../modules/helpers/helpers";
    import ApiSelectInput from "../form_inputs/ApiSelectInput";

    export default {
        name: "NationalityEdit",
        components: {ApiSelectInput, ErrorDisplay},
        props: {
            id: null,
        },
        data: function () {
            return {
                errorLoading : false,
                nGroups: [],
                isLoading: false,
                formData: {},
                errors : {},
                apiEndpoint: baseApi() + 'nationality-groups/dropdown'
            }
        },
        created() {
            if (this.id != null) {
                this.loadModel();
            }
        },
        methods: {

            loadModel() {
                this.isLoading = true;
                getResource(nationalityDetail(this.id)).then((resp) => {
                    var data = resp.data.success.data;
                    //TRANSFORM DATA
                    if (data.nationality_group !== null) {
                        this.nGroups = [{value: data.nationality_group.id, name: data.nationality_group.name}]
                    }
                    this.formData = data;
                    this.isLoading = false;
                }).catch((ex) => {
                    console.log(ex);
                    this.isLoading = false;
                });
            },
            saveModel() {
                this.isLoading = true;
                this.errors = {};
                saveResource(nationalitySave,this.formData).then((resp) => {
                    this.isLoading = false;
                    this.$router.push({'name' : 'nationalities', params: {data_updated : true}}).catch(error => {});
                }).catch((err) => {
                    if(containsErrors(err)) {
                        this.errors = err.data.errors;
                    }
                    this.isLoading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
